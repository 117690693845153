import { create } from 'apisauce';
import config from '../config';
import apiBase from './apiBase';

const api = create({
  baseURL: config.ROOVEE_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json'
  },
  timeout: 10000
});

class AuthenticationApi {
  static signIn(signInData) {
    return api.post('/users/authenticate', signInData);
  }

  static me() {
    return apiBase.create().get(`/users/me`);
  }

  static activateAccount(accountActivationData) {
    return api.post('/users/activate', accountActivationData);
  }

  static recoverPassword(recoverPasswordData) {
    return api.post('/users/recoverPassword', recoverPasswordData);
  }

  static resetPassword(resetPasswordData) {
    return api.post('/users/setPassword', resetPasswordData);
  }

  static changePassword(passwordData) {
    return apiBase.create().post('/users/changePassword', passwordData);
  }

  static getPermissions() {
    return api.get('/users/permissions');
  }

  static checkTenant() {
    return api.head('/tenants/check');
  }
}

export default AuthenticationApi;
