import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import FormTextField from '../../../form/redux-form/FormTextField';
import FormSelect from '../../../form/redux-form/FormSelect';
import numberFormatter from '../../../../utils/numberFormatter';
import { fareSanctionAdjustmentFormValidation } from '../../../../models/fareAdjustments/FareAdjustmentValidation';
import { SanctionType } from '../../../../models/configuration/PricingModel';
import FareSanctionAdjustmentInfo from './FareAdjustmentInfo';

let FareSanctionAdjustmentForm = ({ totalAmount, totalRefund, currentAmount, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        {currentAmount > 0 && <FareSanctionAdjustmentInfo message={`Opłata zostanie podwyższona o ${numberFormatter.format(currentAmount)} zł`} />}
        {-currentAmount > totalAmount && <FareSanctionAdjustmentInfo message={`Klient otrzyma bonus w wysokości ${numberFormatter.format(-(totalAmount + currentAmount))} zł`} />}
      </div>
      <div>
        <Field
          name="amount"
          type="number"
          component={FormTextField}
          label="Kwota"
          parse={val => val ? Number.parseFloat(val) : ''}
          disabled={totalRefund}
          endAdornmentText="zł"
        />
      </div>
      <div>
        <Field
          name="sanctionType"
          component={FormSelect}
          values={SanctionType}
          label="Rodzaj kary/sankcji"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="comment"
          type="text"
          component={FormTextField}
          label="Komentarz (widoczny tylko dla operatorów)"
          fullWidth
        />
      </div>
    </form>
  );
};

FareSanctionAdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  totalAmount: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  totalRefund: PropTypes.bool,
  currentAmount: PropTypes.number,
};

FareSanctionAdjustmentForm = reduxForm({
  form: 'fareSanctionAdjustment',
  validate: fareSanctionAdjustmentFormValidation,
})(FareSanctionAdjustmentForm);

const selector = formValueSelector('fareSanctionAdjustment');
FareSanctionAdjustmentForm = connect(
  state => ({
    totalRefund: selector(state, 'totalRefund'),
    currentAmount: selector(state, 'amount'),
  })
)(FareSanctionAdjustmentForm);

export default FareSanctionAdjustmentForm;
