import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../../form/redux-form/FormTextField';
import FormSelect from "../../form/redux-form/FormSelect";

const resultOfFareIssue = [
  {
    name: 'Uznana',
    value: 1,
  },
  {
    name: 'Odrzucona',
    value: 2,
  },
]

const resolveFareIssueFormValidation = values => {
  const errors = {};

  if (!values.result) {
    errors.result = 'Pole jest wymagane';
  }

  if (!values.resolutionComments) {
    errors.resolutionComments = 'Pole jest wymagane';
  }

  return errors;
};

let ResolveFareIssueForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="resolutionComments"
          component={FormTextField}
          label="Notatka"
          fullWidth
          multiline
          rows={8}
          style={{width: 400}}
        />
        <Field
          name="result"
          component={FormSelect}
          style={{ width: 400 }}
          values={resultOfFareIssue}
          label="Wybierz status reklamacji"
        />
      </div>
    </form>
  );
};

ResolveFareIssueForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

ResolveFareIssueForm = reduxForm({
  form: 'resolveFareIssue',
  validate: resolveFareIssueFormValidation,
})(ResolveFareIssueForm);

export default ResolveFareIssueForm;
