import jwtDecode from 'jwt-decode';

export class AuthModel {
  constructor(jwtToken, permissions, currentUserData, isOneTimeToken) {
    if (isOneTimeToken) {
      this.oneTimeToken = jwtToken
    } else if (!jwtToken) {
      this.id = null;
      this.email = null;
      this.firsName = null;
      this.lastName = null;
      this.lastName = null;
      this.tenantId = null;
      this.oneTimeToken = null;
      this.signedIn = false;
      this.permissions = [];
      this.tenantValidity = { lastChecked: null };
      this.currentUserData = {
        isTOPT2FAEnable: null,
        requireChangePassword: null,
        isLoading: true,
      };
    } else {
      const decoded = jwtDecode(jwtToken);
      this.oneTimeToken = null;
      this.id = decoded.id;
      this.email = decoded.email;
      this.firstName = decoded.firstName;
      this.lastName = decoded.lastName;
      this.tenantId = decoded.tenantId;
      this.signedIn = true;
      this.permissions = permissions;
      this.tenantValidity = { lastChecked: null };
      if (currentUserData) {
        this.currentUserData = currentUserData;
      } else {
        this.currentUserData = {
          isTOPT2FAEnable: null,
          requireChangePassword: null,
          isLoading: true,
        };
      }
    }
  }
}

export const UserRoles = Object.freeze({
  client: { value: "client", name: "zamawiający" },
  mechanic: { value: "mechanic", name: "serwisant" },
  operator: { value: "operator", name: "operator" },
  operatorRoovee: { value: "operatorRoovee", name: "operator - ROOVEE" },
  coordinator: { value: "coordinator", name: "koordynator" },
  operatorAdministrator: { value: "operatorAdministrator", name: "administrator" },
  operatorAdministratorRoovee: { value: "operatorAdministratorRoovee", name: "administrator - Roovee" }
});
