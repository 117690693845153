import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ResolveFareIssueDialog from './ResolveFareIssueDialog';
import { FareIssueStatus } from '../../../reducers/reports/rides/models';

class FareIssueDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resolveFareIssueDialogVisible: false,
      submitting: false,
    };

    this.handleShowDialog = this.handleShowDialog.bind(this);
    this.handleResolveFareIssue = this.handleResolveFareIssue.bind(this);
    this.handleResolveFareIssueCancel = this.handleResolveFareIssueCancel.bind(this);
  }

  handleShowDialog() {
    this.setState({ resolveFareIssueDialogVisible: true });
  }

  handleResolveFareIssue(data) {
    this.setState({ submitting: true });
    this.props.resolveFareIssue(data)
      .then(() => this.setState({ resolveFareIssueDialogVisible: false, submitting: false }));
  }

  handleResolveFareIssueCancel() {
    this.setState({ resolveFareIssueDialogVisible: false });
  }

  getResultOfFareIssue(result){
    switch (result) {
      case 'acknowledged': return 'Uznana';
      case 'rejected': return 'Odrzucona';
      default: return 'Brak informacji';
    }
  }

  renderResolutionDetails(details) {
    return (
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Data zgłoszenia reklamacji</Typography>
          <Typography>{moment(details.reportedAt).local().format('DD.MM.YYYY HH:mm')}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Data zamknięcia</Typography>
          <Typography>{moment(details.resolvedAt).local().format('DD.MM.YYYY HH:mm')}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Decyzja</Typography>
          <Typography>{this.getResultOfFareIssue(details.result)}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Imię i nazwisko</Typography>
          <Typography>{details.resolvedBy.firstName} {details.resolvedBy.lastName}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography color="textSecondary">Notatka</Typography>
          <Typography>{details.resolutionComments}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderResolveFareIssueOptions(details) {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={6} md={2}>
            <Typography color="textSecondary">Data zgłoszenia reklamacji</Typography>
            <Typography>{moment(details.reportedAt).local().format('DD.MM.YYYY HH:mm')}</Typography>
          </Grid>
        </Grid>
        <ResolveFareIssueDialog
          open={this.state.resolveFareIssueDialogVisible}
          onSave={this.handleResolveFareIssue}
          onCancel={this.handleResolveFareIssueCancel}
          submitting={this.state.submitting}
        />
        <Button disabled={!this.props.authorized} variant="contained" size="small" color="primary" onClick={this.handleShowDialog} style={{ marginTop: 16 }}>Zamknij reklamację</Button>
      </React.Fragment>
    );
  }

  render() {
    const { details } = this.props;
    return details.status === FareIssueStatus.resolved.value ? this.renderResolutionDetails(details) : this.renderResolveFareIssueOptions(details);
  }
}

FareIssueDetails.propTypes = {
  details: PropTypes.object.isRequired,
  resolveFareIssue: PropTypes.func.isRequired,
  authorized: PropTypes.bool.isRequired,
};

export default FareIssueDetails;
