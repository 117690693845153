import reportsApi from '../../../api/reportsApi';
import rentalsApi from '../../../api/rentalsApi';
import { actions as notificationActions } from '../../../actions/notificationActions';
import * as logger from '../../../utils/logger';

export const types = {
  LOAD_RIDE_DETAILS_REPORT: 'LOAD_RIDE_DETAILS_REPORT',
  LOAD_RIDE_DETAILS_REPORT_SUCCESS: 'LOAD_RIDE_DETAILS_REPORT_SUCCESS',
  ADJUST_FARE_SUBMIT: 'ADJUST_FARE_SUBMIT',
  ADJUST_FARE_SUCCESS: 'ADJUST_FARE_SUCCESS',
  ADJUST_FARE_FAILURE: 'ADJUST_FARE_FAILURE',
  RESOLVE_FARE_ISSUE: 'RESOLVE_FARE_ISSUE',
  RESOLVE_FARE_ISSUE_SUCCESS: 'RESOLVE_FARE_ISSUE_SUCCESS',
  FINISH_RIDE: 'FINISH_RIDE',
  FINISH_RIDE_SUCCESS: 'FINISH_RIDE_SUCCESS',
  RESTART_RIDE: 'RESTART_RIDE',
  RESTART_RIDE_SUCCESS: 'RESTART_RIDE_SUCCESS',
  RESTART_RIDE_FAILURE: 'RESTART_RIDE_FAILURE',
};

export const actions = {

  loadRideDetailsReport: (rideId) => (dispatch) => {
    dispatch({ type: types.LOAD_RIDE_DETAILS_REPORT, rideId });
    return reportsApi.getRideDetails(rideId).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_RIDE_DETAILS_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },

  restartRide: (rideId, currentUser) => (dispatch) => {
    dispatch({ type: types.RESTART_RIDE, rideId});
    return rentalsApi.restartRide(rideId).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.RESTART_RIDE_SUCCESS, currentUser });
        dispatch(notificationActions.showSuccess('Przejazd został wznowiony'));
      } else {
        dispatch(notificationActions.showError('Wystąpił problem podczas wznawiania przejazdu'));
        return logger.error(res.data);
      }
    })
  },

  adjustRideFare: (rideId, data) => (dispatch) => {
    dispatch({ type: types.ADJUST_FARE_SUBMIT });

    return rentalsApi
      .adjustRideFare(rideId, data)
      .then(response => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.ADJUST_FARE_SUCCESS, data, adjustedFinalFare: response.data.adjustedFinalFare });
          dispatch(notificationActions.showSuccess('Zaktualizowano opłatę za przejazd'));
        } else {
          dispatch({ type: types.ADJUST_FARE_FAILURE });
          if (response.data && response.data.failure.errorCode === 'errorCode.operator.rental.adjustmentCannotCrossZero') {
            dispatch(notificationActions.showError(response.data.failure.message));
            return logger.error(response.data);
          }

          dispatch(notificationActions.showError('Aktualizacja opłaty nie powiodła się'));
          return logger.error(response.data);
        }
      });
  },

  resolveFareIssue: (rentalId, data, currentUser) => (dispatch) => {
    dispatch({ type: types.RESOLVE_FARE_ISSUE, rentalId });
    return rentalsApi.resolveFareIssue(rentalId, data).then(response => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.RESOLVE_FARE_ISSUE_SUCCESS, data, currentUser });
        dispatch(notificationActions.showSuccess('Reklamacja została zamknięta'));
      } else {
        dispatch(notificationActions.showError('Wystąpił problem podczas zamykania reklamacji'));
        return logger.error(response.data);
      }
    });
  },

  finishRide: (rentalId, data, currentUser) => (dispatch) => {
    dispatch({ type: types.FINISH_RIDE, rentalId });
    return rentalsApi.finishRide(rentalId, data).then(response => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.FINISH_RIDE_SUCCESS, data, currentUser });
        dispatch(notificationActions.showSuccess('Przejazd został zakończony'));
      } else {
        dispatch(notificationActions.showError('Wystąpił problem podczas kończenia przejazdu'));
        return logger.error(response.data);
      }
    });
  },

};
