
import initialState from '../../initialState';
import Immutable from 'seamless-immutable';
import { types } from './actions';
import { RideTelemetryModel } from './models';
import { RideRecordModel, FareIssueStatus } from '../rides/models';

export default function rideDetailsReportReducer(state = Immutable(initialState.reports.rideDetails), action) {
  switch (action.type) {
    case types.LOAD_RIDE_DETAILS_REPORT: {
      return Immutable.merge(state, {
        loading: true,
        summary: {},
        telemetry: [],
      });
    }
    case types.LOAD_RIDE_DETAILS_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        loading: false,
        telemetry: action.result.rideTelemetry.map(d => new RideTelemetryModel(d)),
        summary: new RideRecordModel(action.result),
      });
    }
    case types.ADJUST_FARE_SUCCESS: {
      const { data, adjustedFinalFare } = action;

      const summary = Immutable.merge(state.summary, {
        finalFare: adjustedFinalFare,
        fareAdjustments: [...state.summary.fareAdjustments, {
          amount: data.amount, comment: data.comment,
          date: new Date(), category: data.category, paymentType: data.paymentType, sanctionType: data.sanctionType,
        }]
      });

      return Immutable.merge(state, {
        summary: summary,
      });
    }
    case types.RESOLVE_FARE_ISSUE_SUCCESS: {
      const { data, currentUser } = action;

      const summary = Immutable.merge(state.summary, {
        fareIssue: {
          status: FareIssueStatus.resolved.value,
          reportedAt: state.summary.fareIssue.reportedAt,
          resolvedAt: Date(),
          resolvedBy: { firstName: currentUser.firstName, lastName: currentUser.lastName },
          resolutionComments: data.resolutionComments,
        },
      });

      return Immutable.merge(state, {
        summary: summary,
      });
    }
    case types.FINISH_RIDE_SUCCESS: {
      const { data, currentUser } = action;
      const summary = Immutable.merge(state.summary, {
        finishedAt: data.finishedAt.toDate(),
        manualFinishInfo: { finishedBy: { firstName: currentUser.firstName, lastName: currentUser.lastName }, comments: data.comments },
        ongoingPause: false,
        ongoingPauseDate: null,
      });

      return Immutable.merge(state, {
        summary: summary,
      });
    }
    case types.RESTART_RIDE_SUCCESS: {
      const { currentUser } = action;
      const summary = Immutable.merge(state.summary, {
        finishedAt: null,
        manualFinishInfo: null,
        manualRestartInfo: { restartedBy: { firstName: currentUser.firstName, lastName: currentUser.lastName } },
        ongoingPause: false,
        ongoingPauseDate: null,
        finalFare: null,
        fareAdjustments: null,
        averageSpeed: null,
        traceLength: null,
        rideDuration: null,
        pricingCalculation: null
      });

      return Immutable.merge(state, {
        summary: summary,
      });
    }
    default:
      return state;
  }
}
