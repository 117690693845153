import initialState from './initialState';
import localStorageProvider from '../utils/localStorageProvider';
import Immutable from 'seamless-immutable';
import { types } from '../actions/authActions';
import { types as twoFATypes} from '../actions/twoFAActions';
import { AuthModel } from '../models/auth/AuthModel';

export default function authReducer(
  state = Immutable(new AuthModel(
    localStorageProvider.getItem('rooveeAuthToken'),
    JSON.parse(localStorageProvider.getItem('rooveePermissions')))),
  action) {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS:
      return state.merge(new AuthModel(action.token, [], null, action.isOneTimeToken));
    case types.GET_PERMISSIONS_SUCCESS:
      return state.merge(Object.assign({}, state, { permissions: action.permissions }));
    case types.SIGN_IN_FAILURE:
      return state.merge(initialState.auth);
    case types.SIGNED_OUT:
      return state.merge(initialState.auth);
    case types.CHECK_TENANT_SUCCESS:
      return state.merge(Object.assign({}, state, { tenantValidity: { lastChecked: action.checked } }));
    case types.CURRENT_USER_DATA:
      return state.merge({ currentUserData: {
          isTOPT2FAEnable: null,
          requireChangePassword: null,
          isLoading: true,
        }});
    case types.CURRENT_USER_DATA_SUCCESS:
      return state.merge({ currentUserData: {
          isTOPT2FAEnable: action.data.isTOPT2FAEnable,
          requireChangePassword: action.data.requireChangePassword,
          userRole: action.data.userRole,
          isLoading: false,
        }});
    case types.CURRENT_USER_DATA_FAILURE:
      return state.merge({ currentUserData: {
          isTOPT2FAEnable: null,
          requireChangePassword: null,
          isLoading: false,
        }});
    case twoFATypes.ENABLE_TOTP_2FA_SUCCESS:
      return state.merge({ currentUserData: {...state.currentUserData, isTOPT2FAEnable: true} });
    case twoFATypes.DISABLE_TOTP_2FA_SUCCESS:
      return state.merge({ currentUserData: {...state.currentUserData, isTOPT2FAEnable: false} });
    default:
      return state;
  }
}
