import stringLengthConstraints from '../validation/stringLengthConstraints';
import validate from 'validate.js';

export const fareAdjustmentFormValidation = values => {
  const constraints = {
    amount: {
      presence: { message: 'Kwota jest wymagana' },
      numericality: {
        notEqualTo: 0,
        message: 'Opłata musi być większa lub mniejsza od 0',
      },
    },
    paymentType: {
      presence: { message: 'Wybranie typu opłaty jest wymagane' },
    },
    comment: {
      presence: { message: 'Komentarz jest wymagany' },
      length: {
        minimum: stringLengthConstraints.arbitraryLonger.min,
        maximum: stringLengthConstraints.arbitraryLonger.max,
        message: `Komentarz musi składać się z maksymalnie ${stringLengthConstraints.arbitraryLonger.max} znaków`,
      }
    },
  };

  return validate(values, constraints, { fullMessages: false });
};

export const fareSanctionAdjustmentFormValidation = values => {
  const constraints = {
    amount: {
      presence: { message: 'Kwota jest wymagana' },
      numericality: {
        notEqualTo: 0,
        message: 'Kara musi być większa lub mniejsza od 0',
      },
    },
    sanctionType: {
      presence: { message: 'Wybranie typu sankcji/kary jest wymagane' },
    },
    comment: {
      presence: { message: 'Komentarz jest wymagany' },
      length: {
        minimum: stringLengthConstraints.arbitraryLonger.min,
        maximum: stringLengthConstraints.arbitraryLonger.max,
        message: `Komentarz musi składać się z maksymalnie ${stringLengthConstraints.arbitraryLonger.max} znaków`,
      }
    },
  };

  return validate(values, constraints, { fullMessages: false });
};
