import twoFAApi from "../api/twoFAApi";
import {actions as notificationActions} from "./notificationActions";
import {replace} from "connected-react-router";
import routePaths from "../routePaths";

export const types = {
  GET_TOTP_SECRET_CODE_SUBMIT: 'GET_TOTP_SECRET_CODE_SUBMIT',
  GET_TOTP_SECRET_CODE_SUCCESS: 'GET_TOTP_SECRET_CODE_SUCCESS',
  GET_TOTP_SECRET_CODE_FAILURE: 'GET_TOTP_SECRET_CODE_FAILURE',
  ENABLE_TOTP_2FA_SUBMIT: 'ENABLE_TOTP_2FA_SUBMIT',
  ENABLE_TOTP_2FA_SUCCESS: 'ENABLE_TOTP_2FA_SUCCESS',
  ENABLE_TOTP_2FA_FAILURE: 'ENABLE_TOTP_2FA_FAILURE',
  DISABLE_TOTP_2FA_SUBMIT: 'DISABLE_TOTP_2FA_SUBMIT',
  DISABLE_TOTP_2FA_SUCCESS: 'DISABLE_TOTP_2FA_SUCCESS',
  DISABLE_TOTP_2FA_FAILURE: 'DISABLE_TOTP_2FA_FAILURE',
};


export const actions = {
  getTOTPSecretCode: () =>
    async dispatch => {
      dispatch({ type: types.GET_TOTP_SECRET_CODE_SUBMIT });
      const result = await twoFAApi.getTOTPSecretCode();
      if (result.ok) {
        if (result.data.success){
          dispatch({type: types.GET_TOTP_SECRET_CODE_SUCCESS, data: result.data});
        } else {
          dispatch({ type: types.GET_TOTP_SECRET_CODE_FAILURE });
        }
      } else {
        dispatch({ type: types.GET_TOTP_SECRET_CODE_FAILURE });
      }
    },
  enableTOTP2FA: (code, password) =>
    async dispatch => {
      dispatch({ type: types.ENABLE_TOTP_2FA_SUBMIT });
      const result = await twoFAApi.enableTOTP2FA({code, password});
      if (result.ok) {
        if(result.data.failure){
          dispatch({ type: types.ENABLE_TOTP_2FA_FAILURE });
          if(result.data.failure.errorCode === "errorCode.userManagement.emailLogin.wrongPassword"){
            dispatch(notificationActions.showError('Błędne hasło'));
          } else
          if(result.data.failure.errorCode === "errorCode.userManagement.emailLogin.wrongTOTPCode"){
            dispatch(notificationActions.showError('Błędny kod'));
          }
        } else {
          dispatch({ type: types.ENABLE_TOTP_2FA_SUCCESS })
          dispatch(replace(routePaths.account.me));
        }
      } else {
        dispatch(notificationActions.showError('Wystapił problem. Prosimy spróbować później'));
        dispatch({ type: types.ENABLE_TOTP_2FA_FAILURE });
      }
    },
  disableTOTP2FA: (password) =>
    async dispatch => {
      dispatch({ type: types.DISABLE_TOTP_2FA_SUBMIT });
      const result = await twoFAApi.disableTOTP2FA({password});
      if (result.ok) {
        if(result.data.failure){
          dispatch({ type: types.DISABLE_TOTP_2FA_FAILURE });
          if(result.data.failure.errorCode === "errorCode.userManagement.emailLogin.wrongPassword"){
            dispatch(notificationActions.showError('Błędne hasło'));
          }
        } else {
          dispatch({ type: types.DISABLE_TOTP_2FA_SUCCESS });
          dispatch(replace(routePaths.account.me));
        }
      } else {
        dispatch(notificationActions.showError('Wystapił problem. Prosimy spróbować później'));
        dispatch({ type: types.DISABLE_TOTP_2FA_FAILURE });
      }
    },
};
