import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { finishRideFormValidation } from '../../../models/rides/RideValidation';
import FormTextField from '../../form/redux-form/FormTextField';
import FormDateTimeField from '../../form/redux-form/FormDateTimeField';
import FormMapField from '../../form/redux-form/FormMapField';
import { TextField } from '@material-ui/core';

let FinishRideForm = ({ handleSubmit, rideSummary, zones }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <TextField
          disabled
          margin="dense"
          value={moment(rideSummary.startedAt).format('DD.MM.YYYY HH:mm:ss')}
          label="Czas rozpoczęcia"
        />
      </div>
      <div>
        <Field
          name="finishedAt"
          component={FormDateTimeField}
          label="Czas zakończenia"
          minDate={moment(rideSummary.startedAt).toDate()}
        />
      </div>
      <div>
        <Field
          name="comments"
          component={FormTextField}
          label="Notatka"
          fullWidth
          multiline
          rows={3}
          style={{width: 400}}
        />
      </div>
      <div>
        <Field
          name="finishLocation"
          component={FormMapField}
          label="Lokalizacja zakończenia"
          zones={zones}
        />
      </div>
    </form>
  );
};

FinishRideForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  rideSummary: PropTypes.object.isRequired,
  zones: PropTypes.array,
};

FinishRideForm = reduxForm({
  form: 'finishRide',
  validate: finishRideFormValidation,
})(FinishRideForm);

export default FinishRideForm;
