import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FareAdjustmentForm from './FareAdjustmentForm';
import numberFormatter from '../../../../utils/numberFormatter';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

const FareAdjustmentDialog = ({ open, rideDetails, onSave, onCancel, dispatch, submitting }) => (
  <Dialog open={open}>
    <DialogTitle>Korekta</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Wprowadź kwotę o jaką zostanie powiększona lub zmniejszona opłata za przejazd. Aktualny koszt przejazdu <strong>{numberFormatter.format(rideDetails.finalFare)}</strong> zł.
      </DialogContentText>
      <FareAdjustmentForm onSubmit={onSave} totalAmount={rideDetails.finalFare} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting} >
        Anuluj
      </Button>
      <Button variant="contained" onClick={() => dispatch(submit('fareAdjustment'))} disabled={submitting} color="primary">
        Zapisz
      </Button>
    </DialogActions>
  </Dialog>
);

FareAdjustmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rideDetails: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default connect()(FareAdjustmentDialog);
